import styled from "styled-components";
import mediaqueries from "../mediaqueries";

export default styled.div`
  height: ${props => `${props.size}px`};
  width: 100%;
  background: transparent;

  ${mediaqueries.xs`
    ${props => (props.xs ? `height: ${props.xs}px` : "")}
  `}
  ${mediaqueries.sm`
    ${props => (props.sm ? `height: ${props.sm}px` : "")}
  `}
  ${mediaqueries.md`
    ${props => (props.md ? `height: ${props.md}px` : "")}
  `}
  ${mediaqueries.lg`
    ${props => (props.lg ? `height: ${props.lg}px` : "")}
  `}
`;
