import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import styled from 'styled-components';

const EIGHT_BALL_STATES = { SHOWING: 'showing', SHAKING: 'shaking' }

/**STEP ONE */
const StepOneContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 600px;
  width: 100%;
  margin: 40px 0;
`

const StepOneBall = styled.div`
  background: #0b0b0b;
  height: 600px;
  width: 600px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StepOneWindow = styled.div`
  background-color: #010221;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 3;
  border: 5px solid #111;
  box-shadow: inset 0px 0px 2px 1px #3e3e3e;
`

const StepOneDice = styled.div`
  background: #040bbf;
  height: 100px;
  width: 100px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const StepOneMessage = styled.div`
  color: #82d6ff;
  padding: 10px;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-transform: uppercase;
`

export const StepOne = () => {
  return (
    <StepOneContainer className="my-12 py-20 shadow">
      <StepOneBall>
        <StepOneWindow>
          <StepOneDice>
            <StepOneMessage>
              You can bet on it.
            </StepOneMessage>
          </StepOneDice>
        </StepOneWindow>
      </StepOneBall>
      <Button>pls tell me</Button>
    </StepOneContainer>
  );
}
/** STEP ONE DONE */

/** STEP TWO */

export const StepTwo = () => {
  const [currentIndex, setCurrentIndex] = useState(null);

  const generateResponse = () => {
    setCurrentIndex(getRandomIndex())
  }

  return (
    <StepOneContainer className="my-12 py-20 shadow">
      <StepOneBall>
        <StepOneWindow>
          <StepOneDice>
            <StepOneMessage>
              {RESPONSES[currentIndex]}
            </StepOneMessage>
          </StepOneDice>
        </StepOneWindow>
      </StepOneBall>
      <Button onClick={generateResponse}>pls tell me</Button>
    </StepOneContainer>
  );
}
/** STEP TWO DONE */

/** STEP THREE - animate the window */

export const StepThree = () => {
  const [currentIndex, setCurrentIndex] = useState(null);
  //* Add the various possible states of the 8-ball
  const EIGHT_BALL_STATES = { SHOWING: 'showing', SHAKING: 'shaking' }
  const [currentState, setCurrentState] = useState(EIGHT_BALL_STATES.SHOWING);

  //* Set a random response
  const generateResponse = () => {
    // mark the ball as shaking
    setCurrentState(EIGHT_BALL_STATES.SHAKING);

    // after one second, generate a new response and show the answer again
    window.setTimeout(() => {
      setCurrentIndex(getRandomIndex())
      setCurrentState(EIGHT_BALL_STATES.SHOWING);
    }, 1000);
  }

  return (
    <Container className="my-12 py-20 shadow">
      <StepOneBall>
        <StepOneWindow>
          {/* Add a check for the  */}
          <Dice isShowing={currentState === EIGHT_BALL_STATES.SHOWING}>
            <Message>
              {/* Use the current index to grab the message */}
              {RESPONSES[currentIndex]}
            </Message>
          </Dice>
        </StepOneWindow>
      </StepOneBall>
      {/* Add the onClick handler */}
      <Button onClick={generateResponse}>pls tell me</Button>
    </Container>
  );
}

/** STEP THREE DONE */

/** STEP FOUR -- shaking ball */
const AnimatedBall = styled(motion.div) `
  background: #0b0b0b;
  height: 600px;
  width: 600px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StepFour = () => {
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentState, setCurrentState] = useState(EIGHT_BALL_STATES.SHOWING);
  const generateResponse = () => {
    // mark the ball as shaking
    setCurrentState(EIGHT_BALL_STATES.SHAKING);

    // after one second, generate a new response and show the answer again
    window.setTimeout(() => {
      setCurrentIndex(getRandomIndex())
      setCurrentState(EIGHT_BALL_STATES.SHOWING);
    }, 1000);
  }

  return (
    <Container className="my-12 py-20 shadow">
      {/* Add the variants and animate values to the ball */}
      <AnimatedBall
        variants={ballVariants}
        animate={currentState}
      >
        <Window>
          <Dice isShowing={currentState === EIGHT_BALL_STATES.SHOWING}>
            <Message>
              {RESPONSES[currentIndex]}
            </Message>
          </Dice>
        </Window>
      </AnimatedBall>
      <Button onClick={generateResponse}>pls tell me</Button>
    </Container>
  );
}

/** STEP FOUR DONE */

/** STEP FIVE - adding styles to ball */
const StepFiveBall = styled.div`
  background: radial-gradient(circle at 66% 130%, #333, #0a0a0a 80%, #000000 100%);
  height: 600px;
  width: 600px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const StepFive = () => {
  return (
    <StepOneContainer className="my-12 py-20 shadow">
      <StepFiveBall>
        <StepOneWindow>
          <StepOneDice>
            <StepOneMessage>
              You can bet on it.
            </StepOneMessage>
          </StepOneDice>
        </StepOneWindow>
      </StepFiveBall>
    </StepOneContainer>
  );
}

/** STEP FIVE DONE */

/** STEP SIX - adding styles to ball */
const StepSixBall = styled.div`
  background: radial-gradient(circle at 66% 130%, #333, #0a0a0a 80%, #000000 100%);
  height: 600px;
  width: 600px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    position: absolute;
    background: radial-gradient(circle at 66% 130%, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0) 70%);
    border-radius: 50%;
    bottom: 1.5%;
    left: 2.5%;
    opacity: 0.6;
    height: 100%;
    width: 95%;
    filter: blur(5px);
    z-index: 2;
  }
`
export const StepSix = () => {
  return (
    <StepOneContainer className="my-12 py-20 shadow">
      <StepSixBall>
        <StepOneWindow>
          <StepOneDice>
            <StepOneMessage>
              You can bet on it.
            </StepOneMessage>
          </StepOneDice>
        </StepOneWindow>
      </StepSixBall>
    </StepOneContainer>
  );
}

/** STEP SIX DONE */



// FINAL VERSION
const RESPONSES = [
  "You can bet on it",
  "You? Seriously? No. Just, no",
  "Come on, you're embarrassing yourself",
  "Actually, yeah",
  "YAS KWEEN",
  "Ehhhhhh don't plan on it",
  "Only if you become a completely different person",
]

const getRandomIndex = () => Math.floor(Math.random() * RESPONSES.length)

const ballVariants = {
  shaking: {
    x: [10, -16, 10, -12, 19, -10, 4, -10, 0], y: [10, -9, 5, -10, -6, -10, 6, -10, 6, 0],
  },
  showing: { x: 0, y: 0 },
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 600px;
  width: 100%;
`

const Button = styled.button`
  background: #040bbf;
  color: #82d6ff;
  padding: 15px 30px;
  border-radius: 5px;
  margin-top: 15px;
  transition: 0.3s all ease;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    background: #030be0;
  }
`

const Dice = styled.div`
  background: #040bbf;
  height: 100px;
  width: 100px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: ${props => props.isShowing ? 1 : 0};
  transition: 0.5s all ease;
`

const Message = styled.div`
  color: #82d6ff;
  padding: 10px;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-transform: uppercase;
`

const Ball = styled(motion.div) `
  background: radial-gradient(circle at 66% 130%, #333, #0a0a0a 80%, #000000 100%);
  height: 600px;
  width: 600px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    position: absolute;
    background: radial-gradient(circle at 66% 130%, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0) 70%);
    border-radius: 50%;
    bottom: 1.5%;
    left: 2.5%;
    opacity: 0.6;
    height: 100%;
    width: 95%;
    filter: blur(5px);
    z-index: 2;
  }

  &:after {
    content: "";
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background: radial-gradient(circle at 50% 50%,rgba(255,255,255,0.8), rgba(255,255,255,0) 33%);
    transform: skewX(-20deg);
    filter: blur(10px);
  }
`

const Window = styled.div`
  background-color: #010221;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 3;
  border: 5px solid #111;
  box-shadow: inset 0px 0px 2px 1px #3e3e3e;
`

const MessageContainer = styled.div`
  opacity: ${props => props.isShowing ? 1 : 0};
  transition: 0.5s all ease;
`

export default () => {
  const [currentIndex, setCurrentIndex] = useState(null);
  const EIGHT_BALL_STATES = { SHOWING: 'showing', SHAKING: 'shaking' }
  const [currentState, setCurrentState] = useState(EIGHT_BALL_STATES.SHOWING);

  const generateResponse = () => {
    setCurrentState(EIGHT_BALL_STATES.SHAKING)
    setTimeout(() => {
      setCurrentIndex(getRandomIndex())
      setCurrentState(EIGHT_BALL_STATES.SHOWING);
    }, 1000);
  }

  // const animationState = isShowing ? 'showing' : 'shaking';

  return (
    <Container className="my-12 py-20 shadow">
      <Ball
        variants={ballVariants}
        animate={currentState}
      // transition={{ type: 'spring' }}
      >
        <Window>
          <Dice isShowing={currentState === EIGHT_BALL_STATES.SHOWING}>
            <Message>
              {currentIndex || currentIndex === 0 ? RESPONSES[currentIndex] : ''}
            </Message>
          </Dice>
        </Window>
      </Ball>
      <Button onClick={() => generateResponse()}>pls tell me</Button>
    </Container>
  );
}
