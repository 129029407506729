import styled from "styled-components";

const P = styled.p`
  text-align: ${props => `${props.alignment || P.ALIGNMENT.LEFT}`};
  font-family: Futura, Arial, Helvetica, sans-serif;
  line-height: 1.2;
  margin-top: ${props => `${props.theme ? props.theme.spacing * 2 : 0}px`};
  font-size: ${props => (props.size ? `${props.size}px` : "1rem")};
  color: ${props => props.color};
  opacity: inherit;
  transition: inherit;
`;

P.ALIGNMENT = {
  CENTER: "center",
  LEFT: "left",
  RIGHT: "right"
};

export default P;
