module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.ico"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/opt/build/repo/src/templates/post-layout.js"},"gatsbyRemarkPlugins":[{"resolve":"@weknow/gatsby-remark-codepen","options":{"defaultTab":"js,result","height":400}},{"resolve":"gatsby-remark-images","options":{"maxWidth":10000,"sizeByPixelDensity":true,"wrapperStyle":"box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25); margin-top: 24px; margin-bottom: 24px;"}}]},
    },{
      plugin: require('../node_modules/@pauliescanlon/gatsby-mdx-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
