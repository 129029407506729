// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-index-js": () => import("./../src/pages/posts/index.js" /* webpackChunkName: "component---src-pages-posts-index-js" */),
  "component---src-pages-posts-stripe-stripes-js": () => import("./../src/pages/posts/stripe-stripes.js" /* webpackChunkName: "component---src-pages-posts-stripe-stripes-js" */),
  "component---src-pages-wedding-js": () => import("./../src/pages/wedding.js" /* webpackChunkName: "component---src-pages-wedding-js" */),
  "component---src-pages-posts-codepen-test-mdx": () => import("./../src/pages/posts/codepen-test.mdx" /* webpackChunkName: "component---src-pages-posts-codepen-test-mdx" */),
  "component---src-pages-posts-hello-md": () => import("./../src/pages/posts/hello.md" /* webpackChunkName: "component---src-pages-posts-hello-md" */),
  "component---src-pages-posts-hide-drafts-mdx-gatsby-md": () => import("./../src/pages/posts/hide-drafts-mdx-gatsby.md" /* webpackChunkName: "component---src-pages-posts-hide-drafts-mdx-gatsby-md" */),
  "component---src-pages-posts-eight-ball-react-framer-motion-mdx": () => import("./../src/pages/posts/eight-ball-react-framer-motion.mdx" /* webpackChunkName: "component---src-pages-posts-eight-ball-react-framer-motion-mdx" */),
  "component---src-pages-posts-entering-hyperspace-canvas-mdx": () => import("./../src/pages/posts/entering-hyperspace-canvas.mdx" /* webpackChunkName: "component---src-pages-posts-entering-hyperspace-canvas-mdx" */),
  "component---src-pages-posts-building-glyphs-chaikin-curves-mdx": () => import("./../src/pages/posts/building-glyphs-chaikin-curves.mdx" /* webpackChunkName: "component---src-pages-posts-building-glyphs-chaikin-curves-mdx" */),
  "component---src-pages-posts-json-to-jsx-code-mdx": () => import("./../src/pages/posts/json-to-jsx-code.mdx" /* webpackChunkName: "component---src-pages-posts-json-to-jsx-code-mdx" */)
}

