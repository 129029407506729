import EightBall, { StepOne, StepTwo, StepThree, StepFour, StepFive, StepSix } from "../../../../src/demos/eight-ball";
import React from 'react';
export default {
  EightBall,
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
  StepFive,
  StepSix,
  React
};