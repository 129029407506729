import { css } from "styled-components"
import THEME from "../theme"

const breakpoints = THEME.breakpoints.values

// Iterate through the sizes and create a media template
const mediaqueries = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label] / 16}em) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export default mediaqueries
