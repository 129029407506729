import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import P from '../components/styles/P';
import mediaqueries from "../components/mediaqueries";
import Spacer from '../components/styles/Spacer';
import Flex from "../components/styles/Flex";
import Twitter from '../assets/twitter.svg'
import Github from '../assets/github.svg'
import Youtube from '../assets/youtube.svg'
import Facebook from '../assets/facebook.svg'
import Instagram from '../assets/instagram.svg'
import Mail from '../assets/mail.svg'

const FullBackground = styled.div`
  padding: 0;
  box-sizing: border-box;
  background: linear-gradient(120deg, rgba(0,0,70,0.8), #1cb5e0 50%);
  height: 100%;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  ${mediaqueries.xs`padding: 20px`}
`;

const Card = styled.div`
  ${mediaqueries.xs`width: 100%;max-width:100%;`}

  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex-basis: 400px;
  width: 400px;
  max-width: none;
  min-width: 400px;
  overflow-x: auto;
  min-height: 650px;
  background: white;
  position: relative;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);

  .header {
    width: 100%;
    height: 400px;
    overflow: hidden;
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Hr = styled.hr`
  width: ${props => props.width || '100%'};
  height: ${props => `${props.height || 1}px`};
  margin: ${props => props.margin || '0'};
`

const Icons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: ${props => props.width || '100%'};
`

const Icon = styled.span`
  stroke: #002020;
  a {
    color: #002020;
  }

  svg {
    transition: 0.3s stroke ease;
  }

  :hover {
    cursor: pointer;
    svg {
      stroke: #55efc4;
    }
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
    image: file(relativePath: { eq: "portrait-demo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 600) {
                ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <FullBackground>
      <Card>
        <div className="header">
          <Img
            fluid={data.image.childImageSharp.fluid}
            imgStyle={{ objectFit: 'cover', objectPosition: '0 100%', width: '100%', height: '100%' }}
          />
        </div>
        <div className="body">
          <Spacer size={40} />
          <P size={30}>Puggles McKenzie</P>
          <P>Brand Designer</P>
          <Spacer size={10} />
          <Hr width={'35%'} />
          <Spacer size={30} />

          <Icons width={'70%'}>
            <Icon>
              <a href="https://twitter.com" target="_blank" >
                <Twitter />
              </a>
            </Icon>
            <Icon>
              <a href="https://github.com" target="_blank" >
                <Github />
              </a>
            </Icon>
            <Icon>
              <a href="https://youtube.com" target="_blank" >
                <Youtube />
              </a>
            </Icon>
            <Icon>
              <a href="https://facebook.com" target="_blank" >
                <Facebook />
              </a>
            </Icon>
            <Icon>
              <a href="https://instagram.com" target="_blank" >
                <Instagram />
              </a>
            </Icon>
            <Icon>
              <a href="mailto:#" target="_blank" >
                <Mail />
              </a>
            </Icon>
          </Icons>
        </div>
      </Card>
    </FullBackground >
  )
};
